<template>
  <div v-if="proposal" class="mw-proposal">
    <header class="header">
      <div class="container">

        <router-link v-if="user.loggedIn" :to="{ name: 'home' }" class="logo">
          <img src="@/assets/images/mavio-web-logo.svg" alt="Mavio Web Logo">
        </router-link>

        <span v-else class="logo">
          <img src="@/assets/images/mavio-web-logo.svg" alt="Mavio Web Logo">
        </span>

        <ul v-if="info.language === 'en'" class="navigation" v-scroll-spy-active="{selector: '.link'}" v-scroll-spy-link>
          <li><a class="link">Intro</a></li>
          <li><a class="link">Goals & Objectives</a></li>
          <li><a class="link">Solution</a></li>
          <li><a class="link">Process & Timeline</a></li>
          <li><a class="link">Cost Estimate</a></li>
        </ul>
        <ul v-if="info.language === 'ro'" class="navigation" v-scroll-spy-active="{selector: '.link'}" v-scroll-spy-link>
          <li><a class="link">Introducere</a></li>
          <li><a class="link">Obiective</a></li>
          <li><a class="link">Solutie</a></li>
          <li><a class="link">Etape de Lucru</a></li>
          <li><a class="link">Estimare Costuri</a></li>
        </ul>
        <div>
          <span
            v-if="user.loggedIn"
            class="user-email">
            {{ user.data.email }}
          </span>

          <router-link
            v-if="isAdmin"
            tag="button"
            class="button"
            :to="{ name: 'edit', params: { id: this.$route.params.id }}">
            Edit
          </router-link>
        </div>
      </div>
    </header>

    <div v-if="proposal" class="container" v-scroll-spy="{offset: 100}">
      <Info id="intro" :info="info" />
      <Objectives id="goals-objectives" :objectives="objectives" :info="info"/>
      <Solution :structure="structure" :solution="solution" :info="info" />
      <Process id="process-timeline" :structure="structure" :process="process" :info="info" />
      <Pricing id="cost-estimate" :pricing="pricing" :pricingMonthly="pricingMonthly" :info="info" />
    </div>

  </div>
</template>

<script>
import Solution from '@/components/Solution.vue';
import Process from '@/components/Process.vue';
import Pricing from '@/components/Pricing.vue';
import Objectives from '@/components/Objectives.vue';
import Info from '@/components/Info.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'Proposal',
  components: {
    Solution,
    Process,
    Pricing,
    Objectives,
    Info,
  },

  data() {
    return {
    };
  },

  created() {
    this.$store.dispatch('getProposal', this.$route.params.id);
  },

  computed: {

    isAdmin() {
      if (!this.user.loggedIn) return false;
      const adminUsers = ['j7NPCxmszOgkJkHdE0ZKcazzPcI3', '5GyNgsNYzYYhobJHBJuDGhNHIkH2'];
      return adminUsers.includes(this.user.data.id);
    },

    ...mapGetters({
      user: 'user',
    }),

    proposal() {
      return JSON.parse(JSON.stringify(this.$store.state.proposal));
    },

    rawStructure() {
      return this.proposal.pages;
    },

    rawProcess() {
      return this.proposal.process;
    },

    rawPricing() {
      return this.proposal.pricing;
    },

    solution() {
      return this.proposal.solution;
    },

    objectives() {
      return this.proposal.objectives;
    },

    info() {
      return this.proposal.info;
    },

    structure() {
      const structure = this.rawStructure.map((page) => {
        const newPage = page;
        newPage.hours = 0;

        newPage.sections = page.sections.map((section) => {
          let newSection = section;

          // Calculate time
          if (section.use) {
            newSection = this.findSection(section.use);
            newSection.time.wireframe = 0;
            newSection.time.design = 0;
            newSection.time.development = 0;
            newSection.time.total = 0;
          } else {
            newSection.time.total = newSection.time.wireframe
            + newSection.time.design
            + newSection.time.development;

            newPage.hours += newSection.time.total;
          }

          return newSection;
        });

        newPage.price = newPage.hours * this.info.hourlyRate;

        return newPage;
      });

      return structure;
    },

    totals() {
      const totals = {
        time: {
          wireframe: 0,
          design: 0,
          development: 0,
        },
      };

      this.structure.forEach((page) => {
        page.sections.forEach((section) => {
          totals.time.wireframe += section.time.wireframe;
          totals.time.design += section.time.design;
          totals.time.development += section.time.development;
        });
      });

      return totals;
    },

    process() {
      return this.rawProcess.map((phase) => {
        // Calculate time and duration
        const newPhase = phase;
        if (Array.isArray(phase.time)) {
          const percentage = phase.time[1];
          const work = phase.time[0]; // wireframing, design, development
          newPhase.time = (this.totals.time[work] * percentage) / 100;
          newPhase.duration = Math.round(newPhase.time / this.info.hoursPerDay);
        }
        return newPhase;
      });
    },

    pricing() {
      return this.rawPricing.map((item) => {
        // Calculate time and price
        const newItem = item;
        if (Array.isArray(item.time)) {
          const percentage = item.time[1];
          const work = item.time[0]; // wireframing, design, development
          newItem.time = (this.totals.time[work] * percentage) / 100;
        }
        newItem.price = newItem.time * this.info.hourlyRate;
        return newItem;
      });
    },

    pricingMonthly() {
      return this.proposal.pricingMonthly;
    },
  },

  methods: {

    findSection(id) {
      let foundSection = null;
      this.rawStructure.every((page) => {
        foundSection = page.sections.find((section) => section.id === id);
        if (foundSection) return false;
        return true;
      });

      return JSON.parse(JSON.stringify(foundSection));
    },
  },

};
</script>

<style lang="scss">
  body {
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #304659;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 34px;
  }

  .header {
    width: 100%;
    background: #FDFCFF;
    position: sticky;
    top: 0;
    z-index: 3;
    border-bottom: 1px solid #ededed;

    .logo {
      & img {
        height: 55px;
        margin-top: 6px;
      }
    }

    .container {
      height: 65px;
      margin: 0 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .user-email {
      font-size: 14px;
      margin-right: 10px;
    }

    .navigation {
      list-style: none;
      display: flex;
      flex-direction: row;
      width: auto;

      .link {
        color: #304659;
        text-decoration: none;
        margin: 0 15px;
        padding: 5px;
        border-bottom: 2px solid transparent;
        line-height: 1.7;
        cursor: pointer;

        &:hover, &:active, &.active {
          border-color: #9F4AE2;
        }
      }
    }
  }

  .content-markdown {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.7;

    h2, h3 {
      margin-top: 40px;
    }

    ul {
      li {
        padding: 3px 10px;
        list-style-image: url('../assets/images/list-bullet.svg');

        // &:before {
        //   content:'';
        //   display:inline-block;
        //   height: 10px;
        //   width: 10px;
        //   background-image: url('../assets/images/list-bullet.svg');
        //   background-size: contain;
        //   background-repeat: no-repeat;
        //   padding-left: 10px;
        // }
      }

    }
  }

</style>
