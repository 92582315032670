export default {
  info: {
    name: 'New Proposal',
    date: '25.08.2021',
    version: 'Version 1',
    hourlyRate: 50,
    hoursPerDay: 6,
    currency: 'usd',
    language: 'en',
    preparedFor: {
      name: 'Client Name',
      company: 'Company Name',
      email: 'clientname@company.com',
      country: 'Country',
      city: 'City',
    },
    createdBy: {
      name: 'Viorel Tamas',
      company: 'Mavio Labs SRL',
      email: 'tamasvio@gmail.com',
      country: 'Romania',
      city: 'Arad',
    },
  },
  objectives: '',
  solution: '',
  pricing: [
    {
      id: 1,
      name: 'Research and Wireframe',
      description: 'Phase description lorem ipsum dolor sit amet',
      time: ['wireframe', 100],
      price: 0,
    },
    {
      id: 2,
      name: 'Design',
      description: 'Phase description lorem ipsum dolor sit amet',
      time: ['design', 100],
      price: 0,
    },
    {
      id: 3,
      name: 'Development and Testing',
      description: 'Phase description lorem ipsum dolor sit amet',
      time: ['development', 100],
      price: 0,
    },
    {
      id: 4,
      name: 'Deployment, Production Testing and Training',
      description: 'Phase description lorem ipsum dolor sit amet',
      time: 4,
      price: 0,
    },
  ],
  pricingMonthly: [
    {
      id: 1,
      name: 'Hosting and Maintenance',
      description: 'Phase description lorem ipsum dolor sit amet',
      price: 30,
    },
  ],

  process: [
    {
      id: 1,
      name: 'Kick Off & Content Meeting',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Meeting',
      time: 1,
      duration: 0, // To be calculated based on time
    },
    {
      id: 2,
      name: 'Research and Wireframe',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Work',
      time: ['wireframe', '70'], // 70% of the total wireframing time
      duration: 0, // To be calculated based on time
    },
    {
      id: 3,
      name: 'Wireframe Review',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Meeting',
      time: 1,
      duration: 0, // To be calculated based on time
    },
    {
      id: 4,
      name: 'Wireframe - Updates',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Work',
      time: ['wireframe', '30'], // 30% of the total wireframing time
      duration: 0, // To be calculated based on time
    },
    {
      id: 5,
      name: 'Visual Style Exploration  - 3 Versions',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Work',
      time: ['design', '40'],
      duration: 0,
    },
    {
      id: 6,
      name: 'Visual Style Exploration - Review & Selection',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Meeting',
      time: 1,
      duration: 0,
    },
    {
      id: 7,
      name: 'Design - Home',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Work',
      time: ['design', '20'],
      duration: 0,
    },
    {
      id: 8,
      name: 'Design - Home - Review',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Meeting',
      time: 1,
      duration: 0,
    },
    {
      id: 9,
      name: 'Design - Remaining Pages',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Work',
      time: ['design', '30'],
      duration: 0,
    },
    {
      id: 10,
      name: 'Design - Remaining Pages - Review',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Meeting',
      time: 1,
      duration: 0,
    },
    {
      id: 11,
      name: 'Design - Remaining Pages - Updates',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Work',
      time: ['design', '10'],
      duration: 0,
    },
    {
      id: 12,
      name: 'Final Design Approval',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Meeting',
      time: 1,
      duration: 0,
    },
    {
      id: 13,
      name: 'Frontend / Backend Development and Testing',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Work',
      time: ['development', '85'],
      duration: 0,
    },
    {
      id: 14,
      name: 'Final Functional & Device Compatibility Testing & Updates',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Work',
      time: ['development', '15'],
      duration: 0,
    },
    {
      id: 15,
      name: 'Client Review & Training',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Meeting',
      time: 1,
      duration: 0,
    },
    {
      id: 16,
      name: 'Deployment & Production Testing',
      description: 'Phase description lorem ipsum dolor sit amet',
      type: 'Work',
      time: 0,
      duration: 0,
    },
  ],
  pages: [
    {
      id: 'home',
      name: 'Home',
      level: 0,
      sections: [
        {
          id: 'navigation',
          name: 'Navigation',
          description: 'The main navigation of the website.',
          time: { wireframe: 1, design: 2, development: 4 },
        },
        {
          id: 'hero',
          name: 'Hero',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 1, design: 3, development: 4 },
        },
        {
          id: 'process-and-capabilities',
          name: 'Process & Capabilities',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 1, design: 2, development: 3 },
        },
        {
          id: 'order-simulator',
          name: 'Order Simulator (CTA)',
          description: 'Lorem ipsum dolor sit amet',
          time: { wireframe: 1, design: 2, development: 3 },
        },
        {
          id: 'case-studies',
          name: 'Case Studies',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 1, design: 2, development: 3 },
        },
        {
          id: 'footer',
          name: 'Footer',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 1, design: 1, development: 2 },
        },
      ],
    },

    {
      id: 'process-and-capabilities',
      name: 'Process & Capabilities',
      level: 1,
      sections: [
        { use: 'navigation' },
        {
          id: 'page-intro',
          name: 'Page Intro',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 1, design: 1, development: 3 },
        },
        {
          id: 'main-content',
          name: 'Main Content',
          type: 'main-section',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 1, design: 2, development: 5 },
        },
        { use: 'order-simulator' },
        { use: 'footer' },
      ],
    },

    {
      id: 'case-study',
      name: 'Case Study',
      level: 1,
      sections: [
        { use: 'navigation' },
        { use: 'page-intro' },
        {
          id: 'main-content',
          name: 'Main Content',
          type: 'main-section',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 1, design: 2, development: 5 },
        },
        {
          id: 'other-case-studies',
          name: 'Other case Studies',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 1, design: 1, development: 3 },
        },
        { use: 'order-simulator' },
        { use: 'footer' },
      ],
    },

    {
      id: 'about',
      name: 'About',
      level: 1,
      sections: [
        { use: 'navigation' },
        { use: 'page-intro' },
        {
          id: 'main-content',
          name: 'Main Content',
          type: 'main-section',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 1, design: 2, development: 5 },
        },
        { use: 'footer' },
      ],
    },

    {
      id: 'contact',
      name: 'Contact',
      level: 1,
      sections: [
        { use: 'navigation' },
        { use: 'page-intro' },
        {
          id: 'contact-form',
          name: 'Conatct Form',
          type: 'main-section',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 1, design: 2, development: 5 },
        },
        { use: 'footer' },
      ],
    },

    {
      id: 'order-simulator',
      name: 'Order Simulator',
      level: 1,
      sections: [
        { use: 'navigation' },
        { use: 'page-intro' },
        {
          id: 'simulator-system',
          name: 'Simulator System',
          type: 'main-section',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 3, design: 3, development: 16 },
        },
        { use: 'footer' },
      ],
    },

    {
      id: 'blog-collection',
      name: 'Blog - Collection',
      level: 1,
      sections: [
        { use: 'navigation' },
        { use: 'page-intro' },
        {
          id: 'blog-posts-grid',
          name: 'Blog Posts Grid',
          type: 'main-section',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 1, design: 2, development: 5 },
        },
        { use: 'footer' },
      ],
    },

    {
      id: 'blog-single',
      name: 'Blog - Single',
      level: 1,
      sections: [
        { use: 'navigation' },
        { use: 'page-intro' },
        {
          id: 'editable-content',
          name: 'Editable Content',
          type: 'main-section',
          description: 'Lorem ipsum dolor sit amet.',
          time: { wireframe: 1, design: 3, development: 6 },
        },
        { use: 'footer' },
      ],
    },

    {
      id: 'privacy-policy',
      name: 'Privacy Policy',
      level: 1,
      sections: [
        { use: 'navigation' },
        { use: 'page-intro' },
        { use: 'editable-content' },
        { use: 'footer' },
      ],
    },
  ],
};
