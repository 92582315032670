import Vue from 'vue';
import scrollSpy, { Easing } from 'vue2-scrollspy';
import { auth } from './firebase';
import App from './App.vue';
import router from './router';
import store from './data/store';

Vue.config.productionTip = false;

Vue.use(scrollSpy, {
  easing: Easing.Cubic.In,
});

// Filters
Vue.filter('toCurrency', (value) => {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

auth.onAuthStateChanged((user) => {
  store.dispatch('getUser', user);
  if (user) {
    store.dispatch('getProposals');
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
