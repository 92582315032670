<template>
  <div class="mw-proposal">
    <header class="header">
      <div class="container">
        <router-link :to="{ name: 'home' }" class="logo">
          <img src="@/assets/images/mavio-web-logo.svg" alt="Mavio Web Logo">
        </router-link>
        <div>
          <span class="user-email">{{ user.data.email }}</span>
        </div>
      </div>
    </header>

    <div v-if="proposals" class="content-container">
      <div class="table-actions">
        <button class="button-primary" @click.prevent="newProposal">New Proposal</button>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Company</th>
            <th>Date</th>
            <th>Version</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="proposal in proposals" :key="proposal.id">
            <td>{{ proposal.info.name }}</td>
            <td>{{ proposal.info.preparedFor.company }}</td>
            <td>{{ proposal.info.date }}</td>
            <td>{{ proposal.info.version }}</td>
            <td>
              <router-link class="button button-small button-view" :to="{ name: 'proposal', params: { id: proposal.id }}">View</router-link>
              <button class="button button-small" @click.prevent="deleteProposal(proposal.id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'Proposals',
  components: {

  },

  data() {
    return {

    };
  },

  created() {
  },

  computed: {

    ...mapGetters({
      user: 'user',
    }),

    proposals() {
      return this.$store.state.proposals;
    },

  },

  methods: {
    newProposal() {
      this.$store.dispatch('createProposal');
    },

    deleteProposal(id) {
      window.confirm('Are you sure you want to delete this proposal?');
      this.$store.dispatch('deleteProposal', id);
    },
  },

};
</script>

<style scoped lang="scss">

  .content-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 60px 0;
  }

  .table-actions {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

 .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 50px;

    th {
      text-align: left;
      border-bottom: 2px solid #ececec;
      padding: 10px 20px;
      background: #f1f1f1;

      &.item-number {
        width: 10px;
      }
    }

    td {
      border-bottom: 1px solid #ececec;
      padding: 8px 20px;
    }

    th, td {
      &.price,
      &.time {
        text-align: right;
      }
    }

    tfoot td {
      border-top: 2px solid #ececec;
      padding: 12px 20px;
      border-bottom: none;
      font-weight: bold;
    }

  }

  .button-view {
    margin-right: 10px;
  }

</style>
