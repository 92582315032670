<template>
  <div>
    <div v-if="isLoading" class="loader">
      <grid-loader :loading="true" :color="loaderColor"></grid-loader>
    </div>
    <div v-if="!isLoading" class="page-container">
      <router-view/>
      <footer class="footer"></footer>
    </div>
  </div>
</template>

<script>
import GridLoader from 'vue-spinner/src/GridLoader.vue';

export default {
  components: {
    GridLoader,
  },

  data() {
    return {
      loaderColor: '#9F4AE2',
    };
  },

  created() {

  },

  computed: {
    proposals() {
      return this.$store.state.proposals;
    },

    proposal() {
      return this.$store.state.proposal;
    },

    isLoading() {
      console.log(this.$route.name);
      return !this.proposals && !this.proposal && this.$route.name !== 'login';
    },
  },
};
</script>

<style lang="scss">

  .page-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-primary {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 30px;
    border: 1px solid #9F4AE2;
    border-radius: 5px;
    background: #9F4AE2;
    color: #fff;
    cursor: pointer;
  }

  .button {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 30px;
    border: 1px solid #9F4AE2;
    border-radius: 5px;
    background: #f8f2fd;
    cursor: pointer;
    text-decoration: none;
    color: #304659;
  }

  .button-small {
    padding: 5px 15px;
    font-size: 14px;
    font-weight: normal;
  }

  .footer {
    height: 60px;
    background: #29292C;
    margin-top: auto;
  }
</style>
