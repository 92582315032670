<template>
  <div class="solution">
    <h2 v-if="info.language === 'en'" class="title">Solution</h2>
    <h2 v-if="info.language === 'ro'" class="title">Solutie</h2>

    <div class="content-markdown" v-html="solutionMarkdown"></div>
    <div class="content-markdown">
      <h3 v-if="info.language === 'en'">Website Structure</h3>
      <h3 v-if="info.language === 'ro'">Structura Website</h3>
    </div>

    <div class="structure-container">
      <!-- <div v-for="level in levels" :key="level.id" class="level">
        <div v-for="page in level.pages" :key="page.id" class="page">
          <span class="page-name">{{ page.name }}</span>
          <div class="sections">
            <div v-for="section in page.sections" :key="section.id" class="section">
              {{ section.name }}
            </div>
          </div>
          <ul class="details">
            <li>{{ page.price | toCurrency }}</li>
            <li>{{ page.hours }} Hours</li>
          </ul>
        </div>
      </div> -->

      <div class="level">
        <div v-for="page in structure" :key="page.id" class="page">
          <span class="page-name">{{ page.name }}</span>
          <div class="sections">
            <div
              v-for="section in page.sections"
              :key="section.id"
              :class="{'section': true, 'main-section': section.type === 'main-section'}">
              <span class="name">{{ section.name }}</span>
              <span v-if="info.language === 'en'" class="time">{{ section.time.total }} h</span>
              <span v-if="info.language === 'ro'" class="time">{{ section.time.total }} ore</span>
            </div>
          </div>
          <ul class="details">
            <li>{{ getCurrency(page.price) }}</li>
            <li v-if="info.language === 'en'">{{ page.hours }} hours</li>
            <li v-if="info.language === 'ro'">{{ page.hours }} ore</li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
const marked = require('marked');

export default {
  name: 'Solution',

  props: {
    structure: Array,
    solution: String,
    info: Object,
  },

  data() {
    return {
    };
  },

  computed: {
    solutionMarkdown() {
      return marked(this.solution, { sanitize: true });
    },

    levels() {
      const levels = [];
      const numberOfLevels = 1;
      for (let i = 0; i <= numberOfLevels; i += 1) {
        levels.push({
          id: i,
          pages: this.structure.filter((page) => page.level === i),
        });
      }
      return levels;
    },
  },

  methods: {
    calculatePrice(time) {
      return time.total * this.info.hourlyRate;
    },

    getCurrency(value) {
      if (typeof value !== 'number') {
        return value;
      }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: this.info.currency,
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .solution {
    margin-bottom: 50px;
    padding: 50px 0;
    background: #f8f2fd;
  }

  .title {
    margin-bottom: 50px;
    text-align: center;
  }

  .content-markdown {
    margin: 0 auto 80px auto;
  }

  .structure-container {

  }

  // .level {
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   justify-content: left;
  //   min-height: 200px;
  //   margin: 20px 0;
  // }

  .level {
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(200px, 1fr) );
    gap: 30px 30px;
    margin: 0 50px;
  }

  .page {
    font-size: 12px;
    min-width: 180px;
    // height: 100%;
    min-height: 50px;
    // margin: 20px 20px;
    padding-top: 15px;
    border: 1px solid #ececec;
    // border-top: 5px solid #8c8c8c;
    border-radius: 5px;
    background: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.1);
  }

  .page-name {
    font-size: 13px;
    font-weight: bold;
    margin: 0 15px 10px 15px;
  }

  .sections {
    margin: 0 15px 15px 15px;
    flex-grow: 1;
  }

  .section {
    background: #f1f1f1;
    margin: 3px 0;
    padding: 3px 10px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.main-section {
      min-height: 45px;
    }

    .time {
      color: grey;
    }

    .name {

    }
  }

  .details {
    font-size: 12px;
    color: #4e4e4e;
    // background: #f1f1f1;
    list-style: none;
    margin: 0;
    padding: 5px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
</style>
