<template>
  <div class="page-container" v-if="proposal">
    <div class="title-bar">
      <h1 class="page-title">The Super Editor</h1>
      <div>
        <button
          type="submit"
          class="button"
          @click="update">
          Update Objectives and Solution
        </button>
        <button type="submit" class="button" @click="updateJSON">Update JSON</button>
        <router-link class="button button-close" :to="{ name: 'proposal', params: { id: this.$route.params.id }}">Close</router-link>
      </div>
    </div>

      <div class="editor-container">
        <div class="textarea-container">
          <label for="email">Goals & Objectives</label>
          <textarea
            id='objectives'
            name='objectives'
            v-model="proposal.objectives"
          />
        </div>
        <div class="textarea-container">
          <label for="email">Solution</label>
          <textarea
            id='solution'
            name='solution'
            v-model="proposal.solution"
          />
        </div>
      </div>

      <div class="json-container">
        <label for="jsondata">JSON</label>
        <textarea
          id='jsondata'
          name='jsondata'
          v-model="jsonData"
        />
      </div>

  </div>

</template>

<script>
// import firebase from 'firebase';

const clone = (data) => JSON.parse(JSON.stringify(data));

export default {
  name: 'Edit',

  components: {
  },

  data() {
    return {
      error: null,
      updatedJsonData: null,
    };
  },

  created() {

  },

  computed: {
    proposal() {
      return this.$store.state.proposal;
    },

    jsonData: {
      get() {
        if (this.proposal) {
          const data = clone(this.proposal);
          delete data.objectives;
          delete data.solution;
          return JSON.stringify(data, null, 2);
        }

        return '';
      },

      set(newValue) {
        this.updatedJsonData = newValue;
      },
    },
  },

  methods: {
    update() {
      const data = {
        objectives: this.proposal.objectives,
        solution: this.proposal.solution,
      };

      this.$store.dispatch('updateObjectivesAndSolution', {
        id: this.proposal.id,
        data,
      });
    },

    updateJSON() {
      this.$store.dispatch('updateJSON', {
        id: this.proposal.id,
        data: JSON.parse(this.updatedJsonData),
      });
    },
  },
};

</script>

<style scoped lang="scss">
  .page-container {
    margin: 30px;
  }

  .title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 20px;
  }

  .page-title {
    margin: 0;
    font-size: 30px;
  }

  .editor-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 200px);
  }

  .textarea-container{
    width: 80%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 10px 10px 50px 10px;
  }

  textarea {
    height: 100%;
    width: 100%;
    padding: 20px;
    font-size: 14px;
    line-height: 1.5;
    font-family: "Monaco", courier, monospace;
    border: 1px solid #ededed;
  }

  label {
    font-weight: bold;
  }

  .json-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    textarea {
      height: 80vh;
    }
  }

  .button {
    font-size: 16px;
    background: #b2fbb2;
    border: none;
    padding: 5px 20px;
    margin:   0 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: pink;
    }
  }

  .button-close {
    text-decoration: none;
  }
</style>
