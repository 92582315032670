import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase';
import store from '../data/store';
import Proposals from '../views/Proposals.vue';
import Proposal from '../views/Proposal.vue';
import Login from '../views/Login.vue';
import Edit from '../views/Edit.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/proposals',
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/proposals',
    name: 'proposals',
    component: Proposals,
    meta: { requiresAuth: true },
  },
  {
    path: '/proposal/:id',
    name: 'proposal',
    component: Proposal,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/proposal/:id/edit',
    name: 'edit',
    component: Edit,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/share/:id',
    name: 'share',
    component: Proposal,
    props: true,
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

// Handle authentications
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    const currentUser = await firebase.getCurrentUser();
    if (currentUser) {
      console.log(`Logged in as ${currentUser.email}`);
    }
    if (requiresAuth && !currentUser) {
      next({ name: 'login' });
    } else {
      next();
    }
  }

  if (to.name === 'share') {
    store.dispatch('getProposal', to.params.id);
  }

  next();
});

export default router;
