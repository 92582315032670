import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyDw8XD3H3EuLx-195u_3L6YkNRn1_S_5oY',
  authDomain: 'mavio-web.firebaseapp.com',
  projectId: 'mavio-web',
  storageBucket: 'mavio-web.appspot.com',
  messagingSenderId: '335966372454',
  appId: '1:335966372454:web:752e1955786adaac1423a7',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => new Promise((resolve, reject) => {
  const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    unsubscribe();
    resolve(user);
  }, reject);
});

const auth = firebaseApp.auth();
const db = firebaseApp.firestore();

export { db, auth };
