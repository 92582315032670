<template>
  <div class="objectives">
    <h2 v-if="info.language === 'en'" class="title">Goals & Objectives</h2>
    <h2 v-if="info.language === 'ro'" class="title">Obiective</h2>
    <div class="content-markdown" v-html="objectivesMarkdown"></div>
  </div>
</template>

<script>
const marked = require('marked');

export default {
  name: 'Goals',

  props: {
    objectives: String,
    info: Object,
  },

  data() {
    return {
    };
  },

  computed: {
    objectivesMarkdown() {
      return marked(this.objectives, { sanitize: true });
    },
  },
};
</script>

<style lang="scss" scoped>
  .objectives {
    margin-bottom: 80px;
  }

  .title {
    margin-bottom: 50px;
    text-align: center;
  }

</style>
