<template>
  <div class="pricing">
    <h2 v-if="info.language === 'en'" class="title">Cost Estimate</h2>
    <h2 v-if="info.language === 'ro'" class="title">Estimare Costuri</h2>

    <div class="content">

      <h3 v-if="info.language === 'en'">One Time Costs</h3>
      <table class="table">
        <thead>
          <tr>
            <th class="item-number"></th>
            <th v-if="info.language === 'en'" >Item</th>
            <th v-if="info.language === 'ro'" >Articol</th>
            <th v-if="info.language === 'en'" class="time">Time</th>
            <th v-if="info.language === 'ro'" class="time">Timp</th>
            <th v-if="info.language === 'en'" class="price">Cost</th>
            <th v-if="info.language === 'ro'" class="price">Pret</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in pricing" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td v-if="info.language === 'en'" class="time">{{ item.time }} Hours</td>
            <td v-if="info.language === 'ro'" class="time">{{ item.time }} ore</td>
            <td class="price">{{ getCurrency(item.price) }}</td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td></td>
            <td v-if="info.language === 'en'">Hourly rate: {{ getCurrency(info.hourlyRate) }}</td>
            <td v-if="info.language === 'ro'">Pret / ora: {{ getCurrency(info.hourlyRate) }}</td>
            <td v-if="info.language === 'en'" class="time">{{ totalTime }} Hours</td>
            <td v-if="info.language === 'ro'" class="time">{{ totalTime }} ore</td>
            <td class="price">{{ getCurrency(totalPrice) }}</td>
          </tr>
        </tfoot>
      </table>

      <h3 v-if="info.language === 'en'">Monthly Costs</h3>
      <h3 v-if="info.language === 'ro'">Costuri Lunare</h3>
      <table class="table">
        <thead>
          <tr>
            <th class="item-number"></th>
            <th v-if="info.language === 'en'" >Item</th>
            <th v-if="info.language === 'ro'" >Articol</th>
            <th v-if="info.language === 'en'" class="price">Price</th>
            <th v-if="info.language === 'ro'" class="price">Pret</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in pricingMonthly" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td v-if="info.language === 'en'" class="price">{{ getCurrency(item.price) }} / month </td>
            <td v-if="info.language === 'ro'" class="price">{{ getCurrency(item.price) }} / luna</td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td colspan="2"></td>
            <td v-if="info.language === 'en'" class="price">{{ getCurrency(totalPriceMonthly) }} / month</td>
            <td v-if="info.language === 'ro'" class="price">{{ getCurrency(totalPriceMonthly) }} / luna</td>
          </tr>
        </tfoot>
      </table>

      <div v-if="info.language === 'en'" class="content-markdown">
        <h3>Payment Terms</h3>
        <ul>
          <li><b>{{ getCurrency(getTotalPricePercentage(10)) }}</b> representing <b>10%</b> of the project cost to be paid at project start.</li>
          <li><b>{{ getCurrency(getTotalPricePercentage(30)) }}</b> representing <b>30%</b> of the project cost to be paid after the final website design was reviewed, accepted and delivered.</li>
          <li><b>{{ getCurrency(getTotalPricePercentage(60)) }}</b> representing <b>60%</b> of the project cost to be paid when the project is deployed.</li>
        </ul>
        <br>
        <h3>Quality Guarantee and Full Refund</h3>
        <p>At Mavio Web we are committed to designing and developing high quality website that work flawlessly and provide a great user experience.</p>
        <p>We are offering a full refund on the 10% deposit, if you are not satisfied by the quality of the design you get after our review and update cycle. No questions asked.</p>
      </div>

      <div v-if="info.language === 'ro'" class="content-markdown">
        <h3>Termeni de Plata</h3>
        <ul>
          <li>Preturile NU includ TVA</li>
          <li><b>{{ getCurrency(getTotalPricePercentage(10)) }}</b> reprezentand <b>10%</b> din costul proiectului va fi platit la inceput.</li>
          <li><b>{{ getCurrency(getTotalPricePercentage(30)) }}</b> reprezentand <b>30%</b> din costul proiectului va fi platit dupa designul final a fost acceptat.</li>
          <li><b>{{ getCurrency(getTotalPricePercentage(60)) }}</b> reprezentand <b>60%</b> din costul proiectului va fi platit la final.</li>
        </ul>
        <br>
        <h3>Garantia Calitatii si Rambursare Integrala</h3>
        <p>La Mavio Web ne-am angajat să proiectăm și să dezvoltăm site-uri web de înaltă calitate, care funcționează perfect și oferă o experiență excelentă pentru utilizator.</p>
        <p>Vă oferim o rambursare integrală a depozitului de 10%, dacă nu sunteți mulțumit de calitatea designului obținut după ciclul nostru de revizuire și actualizare.</p>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Pricing',

  props: {
    pricing: Array,
    pricingMonthly: Array,
    info: Object,
  },

  data() {
    return {
    };
  },

  computed: {
    totalTime() {
      return this.pricing.reduce((acc, item) => acc + item.time, 0);
    },

    totalPrice() {
      return this.pricing.reduce((acc, item) => acc + item.price, 0);
    },

    totalPriceMonthly() {
      return this.pricingMonthly.reduce((acc, item) => acc + item.price, 0);
    },
  },

  methods: {
    getTotalPricePercentage(percent) {
      return (this.totalPrice * percent) / 100;
    },

    getCurrency(value) {
      if (typeof value !== 'number') {
        return value;
      }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: this.info.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return formatter.format(value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .pricing {
    margin-bottom: 80px;
  }

  .title {
    margin-bottom: 50px;
    text-align: center;
  }

  .content {
    max-width: 800px;
    margin: 0 auto;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 50px;

    th {
      text-align: left;
      border-bottom: 2px solid #ececec;
      padding: 10px 20px;
      background: #f1f1f1;

      &.item-number {
        width: 10px;
      }
    }

    td {
      border-bottom: 1px solid #ececec;
      padding: 8px 20px;
    }

    th, td {
      &.price,
      &.time {
        text-align: right;
      }
    }

    tfoot td {
      border-top: 2px solid #ececec;
      padding: 12px 20px;
      border-bottom: none;
      font-weight: bold;
    }

  }
</style>
