<template>
  <div class="info">
    <div class="top-container">
      <h4 v-if="info.language === 'en'" class="title">Project Proposal</h4>
      <h4 v-if="info.language === 'ro'" class="title">Oferta Proiect</h4>
      <h1 class="client-name">{{ info.name }}</h1>
      <div class="meta">
        <span class="date">{{ info.date }}</span>
        <span class="version">{{ info.version }}</span>
      </div>
    </div>

    <div class="bottom-container">
      <div class="inner">
        <div class="client-info">
          <span v-if="info.language === 'en'" class="prepared">Prepared for</span>
          <span v-if="info.language === 'ro'" class="prepared">Client</span>
          <p>
            {{ info.preparedFor.name }}<br>
            {{ info.preparedFor.email }}<br>
            {{ info.preparedFor.company }}<br>
            {{ info.preparedFor.city }}, {{ info.preparedFor.country }}
          </p>
        </div>

        <div class="scroll-down">
          <span>Scroll down</span>
          <img src="../assets/images/icon-scroll-down.svg" alt="Scroll Down Icon" width="25px">
        </div>

        <div class="company-info">
          <span v-if="info.language === 'en'" class="prepared">Created by</span>
          <span v-if="info.language === 'ro'" class="prepared">Creat de</span>
          <p>
            {{ info.createdBy.name }}<br>
            {{ info.createdBy.email }}<br>
            {{ info.createdBy.company }}<br>
            {{ info.createdBy.city }}, {{ info.createdBy.country }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Info',

  props: {
    info: Object,
  },

  data() {
    return {
    };
  },

  computed: {

  },
};
</script>

<style lang="scss" scoped>
  .info {
    height: calc(100vh - 65px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
    gap: 0px 0px;
    margin-bottom: 100px;
    background-color: #FDFCFF;
    background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #FDFCFF 22px ),
      repeating-linear-gradient( #faf3ff55, #faf3ff );
    border-bottom: 1px solid #ededed;
  }

  .top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .client-name {
    font-size: 40px;
    text-align: center;
    margin: 0 0 10px 0;
  }

  .title {
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 0;
  }

  .meta {
    text-align: center;
    color: #989898;
    font-size: 16px;

    .date {
      padding: 0 8px;
      border-right: 2px solid #989898;
    }

    .version {
      padding: 0 8px;
    }
  }

  .bottom-container {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .inner {
      padding: 0 30px;
      max-width: 1600px;
      width: calc(100% - 60px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .client-info {
    min-width: 250px;
    padding: 30px;
    border-radius: 5px;
  }

  .company-info {
    min-width: 250px;
    padding: 30px;
    border-radius: 5px;
    text-align: right;
  }

  .prepared {
    font-weight: bold;
    width: 100%;
    // border-bottom: 1px solid #ededed;
    display: inline-block;
  }

  .scroll-down {
    font-size: 11px;
    color: #989898;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    animation: bounce-top 0.8s 5s 1;

    span {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }

</style>

<style>
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}

</style>
