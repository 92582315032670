<template>
  <div class="process">
    <h2 v-if="info.language === 'en'" class="title">Process & Timeline</h2>
    <h2 v-if="info.language === 'ro'" class="title">Etape de Lucru</h2>

    <div class="content">
      <table class="table">
        <thead>
          <tr>
            <th class="phase-number"></th>
            <th v-if="info.language === 'en'">Phase</th>
            <th v-if="info.language === 'ro'">Faza</th>
            <th v-if="info.language === 'en'">Type</th>
            <th v-if="info.language === 'ro'">Tip</th>
            <th v-if="info.language === 'en'" class="duration">Duration</th>
            <th v-if="info.language === 'ro'" class="duration">Durata</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="phase in process" :key="phase.id">
            <td>{{ phase.id }}</td>
            <td>{{ phase.name }}</td>
            <td>{{ translatePhaseType(phase.type) }}</td>
            <td v-if="phase.type === 'Meeting'" class="duration">-</td>
            <td v-if="phase.type != 'Meeting' && info.language === 'en'" class="duration">{{ phase.duration }} Days</td>
            <td v-if="phase.type != 'Meeting' && info.language === 'ro'" class="duration">{{ phase.duration }} Zile</td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td colspan="3"></td>
            <td v-if="info.language === 'en'" class="duration">{{ processDuration }} Days</td>
            <td v-if="info.language === 'ro'" class="duration">{{ processDuration }} Zile</td>
          </tr>
        </tfoot>
      </table>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Process',

  props: {
    structure: Array,
    process: Array,
    info: Object,
  },

  data() {
    return {
    };
  },

  computed: {
    processDuration() {
      return Math.round(this.process.reduce((acc, phase) => acc + phase.duration, 0));
    },
  },

  methods: {
    translatePhaseType(phase) {
      if (this.info.language === 'ro') {
        if (phase === 'Meeting') return 'Intalnire';
        if (phase === 'Work') return 'Lucru';
      }
      return phase;
    },
  },
};
</script>

<style lang="scss" scoped>
  .process {
    min-height: 500px;
  }

  .title {
    margin-bottom: 50px;
    text-align: center;
  }

  .content {
    max-width: 800px;
    margin: 0 auto;
  }

  .table {
    width: 100%;
    border-collapse: collapse;

    th {
      text-align: left;
      border-bottom: 2px solid #ececec;
      padding: 10px 20px;
      background: #f1f1f1;

      &.phase-number {
        width: 10px;
      }
    }

    td {
      border-bottom: 1px solid #ececec;
      padding: 8px 20px;
    }

    th, td {
      &.duration {
        text-align: right;
      }
    }

    tfoot td {
      border-top: 2px solid #ececec;
      padding: 12px 20px;
      border-bottom: none;
      font-weight: bold;
    }

  }
</style>
