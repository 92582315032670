<template>
  <div class="page-container">
    <img src="../assets/images/mavio-web-logo.svg" alt="Mavio Web Logo">

    <div class="form-container">
      <h1 class="page-title">Client Area - Sign In</h1>
      <div v-if="error" class="alert alert-error"> {{error}} </div>
      <form class="login-form" action='#' @submit.prevent='submit'>
        <label for="email">Email Address</label>
        <input
          id='email'
          type='email'
          name='email'
          value
          required
          autofocus
          v-model='form.email'
        />

        <label for="password">Password</label>
        <input
          id='password'
          type='password'
          name='password'
          required
          v-model='form.password'
        />

        <button type="submit" class="button-primary button-sign-in">Sign In</button>
      </form>
    </div>
  </div>

</template>

<script>
import firebase from 'firebase';

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      error: null,
    };
  },

  methods: {

    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          this.$router.replace({ path: '/' });
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
  },
};

</script>

<style scoped lang="scss">

  .page-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 1fr;
    // flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-items: center;
    margin: 0 10px;
  }

  .page-title {
    font-size: 24px;
    text-align: center;
  }

  .form-container {
    align-self: flex-start;
    max-width: 400px;
    width: 100%;
  }

  .login-form {
    background: #FBFBFB;
    padding: 40px 40px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
  }

  label {
    margin-bottom: 2px;
  }

  input {
    font-size: 16px;
    color: #304659;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    border: 1px solid #A8A8A8;

    &:focus, &:active {
      border-color: #9F4AE2;
      outline-color: #9F4AE2;;
    }

  }

  .button-sign-in {
    margin-top: 20px;
  }

  .alert {
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .alert-error {
    background:#ffe2e7;
    text-align: center;
  }

</style>
