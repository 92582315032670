import Vue from 'vue';
import Vuex from 'vuex';
import { db } from '../firebase';
import defaultProposal from './default-proposal';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
    proposals: null,
    proposal: null,
  },

  getters: {
    user(state) {
      return state.user;
    },
  },

  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },

    SET_USER(state, data) {
      state.user.data = data;
    },

    SET_PROPOSALS(state, data) {
      state.proposals = data;
    },

    SET_PROPOSAL(state, data) {
      state.proposal = data;
    },
  },

  actions: {
    getUser({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null);
      if (user) {
        commit('SET_USER', {
          displayName: user.displayName,
          email: user.email,
          id: user.uid,
        });
      } else {
        commit('SET_USER', null);
      }
    },

    getProposal: async ({ commit }, id) => {
      const proposal = await db.collection('proposals').doc(id).get();
      const p = proposal.data();
      p.id = proposal.id;
      commit('SET_PROPOSAL', p);
    },

    getProposals: async ({ commit }) => {
      const dbData = await db.collection('proposals').get();
      const proposals = [];
      dbData.forEach((proposal) => {
        const p = proposal.data();
        p.id = proposal.id;
        proposals.push(p);
      });
      commit('SET_PROPOSALS', proposals);
    },

    createProposal: async ({ dispatch }) => {
      await db.collection('proposals').add(defaultProposal);

      dispatch('getProposals');
    },

    deleteProposal: async ({ dispatch }, id) => {
      await db.collection('proposals').doc(id).delete();

      dispatch('getProposals');
    },

    async updateObjectivesAndSolution({ dispatch }, payload) {
      await db.collection('proposals').doc(payload.id).update({
        objectives: payload.data.objectives,
        solution: payload.data.solution,
      });

      // TODO: Needs improvement
      dispatch('getProposal', payload.id);
    },

    async updateJSON({ dispatch }, payload) {
      if (!payload.data) return;

      await db.collection('proposals').doc(payload.id).update({
        info: payload.data.info,
        pricing: payload.data.pricing,
        pricingMonthly: payload.data.pricingMonthly,
        process: payload.data.process,
        pages: payload.data.pages,
      });

      // TODO: Needs improvement
      dispatch('getProposal', payload.id);
    },
  },
});

export default store;
